    "use client"
    import React from 'react'
    import { useState, useEffect  } from 'react'
    import { useRouter} from "next/navigation"
    import { Roboto } from 'next/font/google'
    import './search.css'
    import Arrow from './Arrow'
    import WordBlocks from './WordBlocks'
    import { PopularApiWord } from '../../app/api/ApiWord'


    const roboto = Roboto({
        weight: '400',
        subsets: ['latin'],
    })

    interface WordObject {
        word: string;
    }
    

    const wordsArray = ['Ideology', 'AValues', 'Values', 'Meaning', 'Argumentation'];


    const Search = () =>  {

        const router = useRouter()

        const [selectedWords, setSelectedWords] = useState<string[]>([]);
        const [inputValue, setInputValue] = useState('');
        const [wordsArray, setWordsArray] = useState<string[]>([]);

        useEffect(() => {
            PopularApiWord()
                .then(response => {
                    if (response && response.popular_words && Array.isArray(response.popular_words)) {
                        const words = response.popular_words.map((wordObj: WordObject) => wordObj.word);
                        setWordsArray(words);
                    } else {
                        console.error('Ошибка: Неверный формат ответа API');
                    }
                })
                .catch(error => {
                    console.error('Ошибка при запросе API:', error);
                });
        }, []);


        const handleWordClick = (word: string) => {
            const popularCards = document.querySelectorAll('.popularCard');
            popularCards.forEach(card => {
                card.classList.remove('disableWords');
            });
            const selectedCard = document.querySelector(`.popularCard[data-words="${word}"]`);
            if (selectedCard) {
                selectedCard.classList.add('disableWords');
            }
            setInputValue(word);
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);

        const popularCards = document.querySelectorAll('.popularCard');
        popularCards.forEach(card => {
            card.classList.remove('disableWords');
        });

        if (value.trim() !== '') {
            popularCards.forEach(card => {
                const dataValue = card.getAttribute('data-words');
                if (dataValue && dataValue === value) {
                    card.classList.add('disableWords');
                }
            });
        }
        };
        useEffect(() => {
            const inputElement = document.querySelector('.searchInput input') as HTMLInputElement;
            if (inputElement) {
                if (selectedWords.some(word => inputElement.value.includes(word))) {
                    inputElement.classList.add('disableWords');
                } else {
                    inputElement.classList.remove('disableWords');
                }
            }
        }, [selectedWords]);

        
        const handleSubmit = async () => {
            try {
                router.push(`/result?data=${inputValue}`);
            } catch (error) {
                console.error("Error sending API request:", error);
            }
        };
        
        const displayedWords = wordsArray.slice(0, 5);

        return (
            <div className="searchInfo">
                <span className="infoText">
                    <p>Enter the word you want to learn more about</p>
                </span>

                <div className="seatchInput">
                    <input 
                        type="search" 
                        name="" 
                        id="" 
                        placeholder='Enter the word to be found'
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                    <span  
                        className={`arrowBtn ${inputValue.trim() === '' ? 'disableArrow' : ''}`} 
                        onClick={handleSubmit}
                    >
                        <Arrow/>
                    </span>
                </div>

                <div className="popularBox">
                    <div className="populatText">
                        <p>Popular words:</p>
                    </div>
                    <div className="popularBoxWords">
                    {displayedWords.map((word, index) => (
                        <div 
                        key={index} 
                        className={`popularCard ${selectedWords.includes(word) ? 'disableWords' : ''}`}
                        data-words={[word]}
                        onClick={() => handleWordClick(word)}
                    >
                            <WordBlocks words={[word]} />
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        )   
    }

    export default  Search;