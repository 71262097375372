"use client"
import { Component } from 'react';

interface WordBlocksProps {
    words: string[];
}

class WordBlocks extends Component<WordBlocksProps> {
  render() {
    return (
      <div>
        {this.props.words.map((word, index) => (
          <p key={index}>
            {word}
          </p>
        ))}
      </div>
    );
  }
}

export default WordBlocks;
