
export default function Arrow() {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                <path d="M33.6699 13.8369L47.8333 28.0002L33.6699 42.1636" stroke="#EC6E05" strokeWidth="3.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.16602 28H47.436" stroke="#EC6E05" strokeWidth="3.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )   
}
